<template>
  <div>
    <json-viewer v-if="jsonData" :value="jsonData" :expand-depth="999" :copyable="true" :theme="'json-viewer json-viewer--pt0 jv-light'">
      <template slot="copy">
        <button class="button button--mini" type="button">
          <span>Копировать</span>
        </button>
      </template>
    </json-viewer>
    <code v-if="code" class="logs">{{ code }}</code>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'Response1c',
  components: {
    JsonViewer
  },
  data() {
    return {
      jsonData: null,
      code: ''
    }
  },
  mounted() {
    this.$store.dispatch('orders/GET_DATA', this.$route.params.id)
      .then(response => {
        if (response.data.codes_1c.length) {
          this.jsonData = JSON.parse(response.data.response_1c)
        } else {
          this.code = response.data.response_1c
        }
      })
  }
}
</script>
